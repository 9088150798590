import React, { useEffect ,useState} from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import DashboardLoading from '../../utils/DashboardLoading';
import {getMethod, patchMethod} from '../../Apis/apis';
import { FiEdit } from "react-icons/fi";
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const H5 = styled.h5`
    margin-bottom: 0;
    color: #333;
    font-size: 15px;
    font-weight: 700;
    display: inline-block;
    margin-right: 10px;
    line-height: 1.1;
    position: relative;
    &:after {
        content: "";
        background-color: #d2d2d2;
        width: 60px;
        height: 1px;
        position: absolute;
        bottom: -20px;
        left: 0;
`
const ActionBtn = styled.button`
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
`

const TitleH1 = styled.h1`
    color: #000;
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items:center;
`

const ParaText = styled.p`
    margin-bottom: 0;
    color:#000;
    font-size: 17px;
    text-align: justify;
    text-indent: 50px;
`

const Message = () => {
  const [loading,setLoading] = useState(false);
  const [message,setMessage] = useState([]);
  const [title,setTitle] = useState("");
  const [text,setText] = useState("");
  const [date,setDate] = useState("");
  const closeRef = useRef();
  const navigate = useNavigate();

  useEffect(()=>{
    (async()=>{
       const res = await getMethod('/announce');
       if(res){
         if(res.data){
           setMessage(res.data);
           setLoading(false);
         }
       };
    })();
 },[]);
 const updateSubmitHandler = async (id) => {
    let hideModal= document.querySelector(".modal-backdrop");
    const data = {
        title,
        date,
        text
    };
    let token = localStorage.getItem("luckyd_auth");
    if(token){
         (async ()=> {
             let res = await patchMethod(`/announce/edit/${id}`,data, token);
             if(res){
                 if(res.message === "jwt malformed"){
                     navigate("/admin/login");
                     if(hideModal){
                         hideModal.classList.remove('modal-backdrop');
                     };
                 };
                 if(res.con){
                     let updateResponse = await getMethod(`/announce`);
                     if(updateResponse){
                         setMessage(updateResponse.data);
                         setLoading(false);
                         setTitle("");
                         setText("")
                         closeRef.current.click();
                     };
                 }else{
                    if(res.message === "invalid token"){
                        navigate("/admin/login");
                        if(hideModal){
                            hideModal.classList.remove('modal-backdrop');
                        };
                        return;
                    };
                    if(res.message === "jwt expired"){
                        navigate("/admin/login");
                        if(hideModal){
                            hideModal.classList.remove('modal-backdrop');
                        };
                        return;
                    };
                    alert(res.message);
                    setLoading(false);
                 };
             }else{
                 navigate("/admin/login");
                 if(hideModal){
                     hideModal.classList.remove('modal-backdrop');
                 };
             }
         })();
    }else{
         navigate("/admin/login");
    };
};
  return (
    <>
       <div className='card h-100'>
            <div className='card-header' style={{
                    backgroundColor: "transparent",
                    borderBottom: 'none',
                    padding: '20px',
                    position: 'relative'}}>
                <H5>Announcement</H5>
            </div>
       <div className='card-body'>
       {
        message.length > 0 &&
        <>
          <TitleH1>
              {`${message[0].title} - ${message[0].date}`}
              
              <ActionBtn data-bs-toggle="modal" data-bs-target={`#model-${message[0]._id}`}>
                    <FiEdit size="30px" color='green' style={{marginRight: "10px"}} />
              </ActionBtn>
          </TitleH1>
          <ParaText>
              {}
          </ParaText>
          <ParaText>
            {message[0].text}
          </ParaText>
          <div className="modal fade" id={`model-${message[0]._id}`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <form onSubmit={(e)=> {
                            e.preventDefault();
                            updateSubmitHandler(message[0]._id)
                        }} customatt={`${message[0]._id}`}>
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">Edit</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label htmlFor="" className="form-label">Title</label>
                                    <input type="text" value={title} className="form-control" id="" placeholder="Title" onChange={(e)=> setTitle(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="" className="form-label">Date</label>
                                    <input type="date" value={date} className="form-control" id="" placeholder="Date" onChange={(e)=> setDate(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="" className="form-label">Note</label>
                                    <textarea style={{resize: "none"}} rows={7} type="text" value={text} className="form-control" id="" placeholder="Note" onChange={(e)=> setText(e.target.value)} />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" ref={closeRef}>Close</button>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
       }
       </div>
    </div>
    {
        
        createPortal( loading && <DashboardLoading />, document.getElementById("portal"))
    }
    </>
  )
}

export default Message
