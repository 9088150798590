import React from 'react';
import {Routes,Route } from "react-router-dom";
import RouteGuard from './Apis/RouteGuard/RouteGuard';
import Alert from './components/dashboard/Alert';
import Changepass from './components/dashboard/Changepass';
import Close from './components/dashboard/Close';
import Home from './components/dashboard/Home';
import Login from './components/dashboard/Login';
import MessageAnnounce from './components/dashboard/MessageAnnounce';
import ResultCreate from './components/dashboard/ResultCreate';
import Main from './components/Main';
import Message from './components/Message';
import Result from './components/Result';
import NotFound from './utils/NotFound';


function App() {
  return (
      <Routes>
          <Route path="/" exact element={
                <Main/>
           }/>
           <Route path="/result" exact element={
                <Result/>
           }/>
           <Route path="/message" exact element={
                <Message/>
           }/>
           <Route path="/admin/home" exact element={
                <RouteGuard>
                    <Home/>
                </RouteGuard>
           }/>

          <Route path="/admin/message" exact element={
                <RouteGuard>
                    <MessageAnnounce/>
                </RouteGuard>
           }/>
           <Route path="/admin/alert" exact element={
                <RouteGuard>
                    <Alert/>
                </RouteGuard>
           }/>
           <Route path="/admin/create-result" exact element={
                <RouteGuard>
                    <ResultCreate/>
                </RouteGuard>
           }/>
           <Route path="/admin/close" exact element={
                <RouteGuard>
                    <Close/>
                </RouteGuard>
           }/>
           <Route path="/admin/change-password" exact element={
                <RouteGuard>
                    <Changepass/>
                </RouteGuard>
           }/>

           <Route path="/admin/login" exact element={
                <Login/>
           }/>
          <Route path="*" element={<NotFound />} />
      </Routes>
  );
}

export default App;
