import React from 'react'
import styled from 'styled-components';
import { ProSidebar, Menu, MenuItem,SidebarHeader, SidebarFooter, SidebarContent  } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { FiHome,FiMessageSquare,FiX,FiKey } from "react-icons/fi";
import { Link } from 'react-router-dom';

const SidebarHeaderDiv = styled.div`
    padding: 24px; 
    text-transform: uppercase; 
    font-weight: bold; 
    font-size: 14px; 
    letter-spacing: 1px; 
    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap;
`

const Sidebar = () => {
  return (
    <ProSidebar style={{height: "100vh"}}>
    <SidebarHeader>
       <SidebarHeaderDiv>Header</SidebarHeaderDiv>
    </SidebarHeader>
    <SidebarContent>
    <Menu iconShape="circle">
        <MenuItem icon={<FiHome />}>
            Dashboard
            <Link to="/admin/home" />
        </MenuItem>
        <MenuItem icon={<FiMessageSquare />}>
            Create Result
            <Link to="/admin/create-result" />
        </MenuItem>
        <MenuItem icon={<FiMessageSquare />}>
            Message
            <Link to="/admin/message" />
        </MenuItem>
        <MenuItem icon={<FiMessageSquare />}>
            Alert Message
            <Link to="/admin/alert" />
        </MenuItem>
        <MenuItem icon={<FiX />}>
            Close
            <Link to="/admin/close" />
        </MenuItem>
        <MenuItem icon={<FiKey />}>
            Change Password
            <Link to="/admin/change-password" />
        </MenuItem>
        {/* <SubMenu title="Components" icon={<FiHome />}>
            <MenuItem>Component 1</MenuItem>
            <MenuItem>Component 2</MenuItem>
        </SubMenu> */}
    </Menu>
    </SidebarContent>
    <SidebarFooter>

    </SidebarFooter>
</ProSidebar>
  )
}

export default Sidebar
