import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import logo from '../images/logo/logo.png';
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { getMethod } from '../Apis/apis';
import { createPortal } from 'react-dom';
import Loading from '../utils/Loading';
import background from '../images/background/bg.png';

const Container = styled.div`
  max-width: 480px;
  min-height: 100vh;
  opacity: 1;
  background-attachment: fixed;
  margin: 0 auto;
  position: relative;
  background: no-repeat top url(${background});
  background-size: 100%;
`

const HeaderDiv = styled.div`
   padding: 10px 20px;
   display:flex;
   align-items: center;
   background-color: ${props => props.theme.primary};
   border-bottom: 1px solid #3c3cff;

   img {
     width: 60px;
     margin-left: 15px;
   }
   button {
      border: none;
      outline: none;
      background-color: transparent;
      font-size: 30px;
      color: #FFF;
      position: absolute;
      right: 20px;

      div {
        position: relative;
      }

      span {
        font-size: 12px;
        background-color: red;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items:center;
        border-radius: 50%;
        position: absolute;
        top: 0;
        right: -5px;
      }
   }
`

const ContainerDiv = styled.div`
   padding: 20px 20px 20px 20px;
`

const TitleH1 = styled.h1`
    color: ${props => props.theme.primary};
    font-size: 27px;
    text-align: center;
    margin-bottom: 10px;
    font-weight: bolder;
    font-family: Merriweather, serif;
`

const DateH1 = styled.h1`
    color: ${props => props.theme.primary};
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    font-family: Merriweather, serif;
`

const ParaText = styled.p`
    margin-bottom: 0;
    color:${props => props.theme.primary};
    font-size: 50px;
    text-align: center;
    font-weight: 700;
    font-family: Merriweather, serif;
    letter-spacing: 2px;
`

const Message = () => {
  const [message,setMessage] = useState([]);
  const [loading,setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(()=>{
     (async()=>{
        const res = await getMethod('/announce');
        if(res){
          if(res.data){
            setMessage(res.data);
            setLoading(false);
          }
        };
     })();
  },[]);
  return (
    <Container>
      <HeaderDiv>
        <FaChevronLeft size="15px" color="#FFF" onClick={()=>navigate(-1)} cursor="pointer" />
        <img src = {logo} alt="Logo" />
      </HeaderDiv>
    <ContainerDiv>
      {
        message.length > 0 &&
        <>
          <div style={{margin:" 50px 0 120px 0"}}>
            <TitleH1>
                {message[0].title}
            </TitleH1>
            <DateH1>
                {message[0].date}
            </DateH1>
          </div>
          <ParaText>
              {message[0].text}
          </ParaText>
        </>
      }
    </ContainerDiv>
    {
        createPortal( loading && <Loading />, document.getElementById("portal"))
    }
    </Container>
  )
}
export default Message;