import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import {io} from "socket.io-client";
import {getMethod} from '../Apis/apis';
import moment from 'moment-timezone';

const ContainerDiv = styled.div`
   padding: 20px;
`
const Ul = styled.ul`

`
const Li = styled.li`
    background-color: #FFF;
    padding: 13px;
    border-radius: 10px;
    border: 1px solid #FFF;
    user-select: none;
    margin: 5px;
    box-shadow: 2px 2px 4px #a8a8aa;
    
    &:last-child {
        margin-bottom: 0;
    }
    span {
        font-weight: bolder;
        color: #908F94;
        font-size: 18px;  
        p {
          margin-bottom: 0;
          text-align: center;
        }
    };
`
const TextBlink = keyframes`
    50% {
        opacity: 1;
    }
    57% {
        opacity: 0;
    }
    64% {
        opacity: 1;
    }
    71% {
        opacity: 0;
    }
    78% {
        opacity: 1;
    }
`
const ResultNum = styled.p`
    font-weight: bolder;
    color: ${props => props.theme.primary};
    font-size: 30px;
    letter-spacing: .6px;
    margin-bottom: 0;
    width: 20%;
    text-align: right;
    @media (min-width: 461px) and (max-width: 470px) {
      font-size: 30px;
    }
    @media (min-width: 421px) and (max-width: 460px) {
      font-size: 26px;
    }
    @media (min-width: 401px) and (max-width: 420px) {
      font-size: 26px;
    }
    @media (min-width: 351px) and (max-width: 400px) {
      font-size: 26px;
    }
    @media (min-width: 340px) and (max-width: 350px) {
      font-size: 25px;
    }
    @media (min-width: 310px) and (max-width: 339px) {
      font-size: 20px;
    }
`
const SetValueFlexDiv = styled.div`
    display: flex;
    align-item: center;
    justify-content: space-between;
    width: 60%;
    span {
      font-weight: normal;
      font-size: 12px;
      background-color: ${props => props.theme.primary};
      color: #FFF;
      padding: 10px;
      border-radius: 20px;
      border: none;
      letter-spacing: .2px;
      width: 47%;
      display: flex;
      align-items: center;
      font-weight: bolder;
      p {
        margin-left: 5px;
      }
      @media (min-width: 461px) and (max-width: 470px) {
        font-size: 12px;
      }
      @media (min-width: 421px) and (max-width: 460px) {
        font-size: 12px;
      }
      @media (min-width: 401px) and (max-width: 420px) {
        font-size: 10px;
      }
      @media (min-width: 351px) and (max-width: 400px) {
        font-size: 9px;
      }
      @media (min-width: 340px) and (max-width: 350px) {
        font-size: 8px;
      }
      @media (min-width: 310px) and (max-width: 339px) {
        font-size: 8px;
      }
    }
`
const TimeResultFlex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;  
`

const DailyResult = () => {
    const [result10,setResult10] = useState("--");
    const [result13,setResult13] = useState("--");
    const [result16,setResult16] = useState("--");
    const [result19,setResult19] = useState("--");
    const [result22,setResult22] = useState("--");
    const [set10,setSet10] = useState('- - - - -');
    const [set13,setSet13] = useState('- - - - -');
    const [set16,setSet16] = useState('- - - - -');
    const [set19,setSet19] = useState('- - - - -');
    const [set22,setSet22] = useState('- - - - -');
    const [value10,setValue10] = useState('- - - - -');
    const [value13,setValue13] = useState('- - - - -');
    const [value16,setValue16] = useState('- - - - -');
    const [value19,setValue19] = useState('- - - - -');
    const [value22,setValue22] = useState('- - - - -');


    let currentDate = moment().tz("Asia/Yangon").format("YYYY-MM-DD");
    useEffect(()=>{
       const fetch = async () => {
        let res = await getMethod(`/number/filter?date=${currentDate}`);
            if(res){
               if(res.data){
                if(res.data.length > 0){
                  res.data.map(obj => {
                      if(obj.time === "10:00 AM"){
                        setResult10(obj.result);
                        setSet10(obj.set);
                        setValue10(obj.value);
                      };

                      if(obj.time === "01:00 PM"){
                        setResult13(obj.result);
                        setSet13(obj.set);
                        setValue13(obj.value);
                      };
    
                      if(obj.time === "04:00 PM"){
                        setResult16(obj.result);
                        setSet16(obj.set);
                        setValue16(obj.value);
                      };
    
                      if(obj.time === "07:00 PM"){
                        setResult19(obj.result);
                        setSet19(obj.set);
                        setValue19(obj.value);
                      };

                      if(obj.time === "10:00 PM"){
                        setResult22(obj.result);
                        setSet22(obj.set);
                        setValue22(obj.value);
                      };
                  });
                };
               }
            }
       };
       fetch();
    },[]);
    
    useEffect(
      () => {
        const socket = io(`${process.env.REACT_APP_HOST}/live`);
        socket.connect();
        socket.on("result", res => {
            if(res){
              switch(res.time) {
                case "10:00 AM":
                  setResult10(res.result);
                  setSet10(res.set);
                  setValue10(res.value);
                  break;
                case "01:00 PM":
                  setResult13(res.result);
                  setSet13(res.set);
                  setValue13(res.value);
                  break;
                case "04:00 PM":
                  setResult16(res.result);
                  setSet16(res.set);
                  setValue16(res.value);
                  break;
                case "07:00 PM":
                  setResult19(res.result);
                  setSet19(res.set);
                  setValue19(res.value);
                  break;
                case "10:00 PM":
                  setResult22(res.result);
                  setSet22(res.set);
                  setValue22(res.value);
                  break;
                default:
                  return;
              };
            }
        });
        return () => {
          socket.disconnect();
        }
      },[]
    );
  return (
    <ContainerDiv>
       <Ul>
          <Li>
            <TimeResultFlex>
                <span style={{width: '20%'}}>
                  <p>10:00</p>
                  <p>AM</p>
                </span>
                <SetValueFlexDiv>
                    <span>Set: <p>{set10}</p></span>
                    <span>Val: <p>{value10}</p></span>
                </SetValueFlexDiv>
                {
                  <ResultNum>{result10}</ResultNum>
                }
            </TimeResultFlex>
            
          </Li>
          <Li>
            <TimeResultFlex>
                <span style={{width: '20%'}}>
                  <p>01:00</p>
                  <p>PM</p>
                </span>
                <SetValueFlexDiv>
                    <span>Set: <p>{set13}</p></span>
                    <span>Val: <p>{value13}</p></span>
                </SetValueFlexDiv>
                {
                  <ResultNum>{result13}</ResultNum>
                }
            </TimeResultFlex>
            
          </Li>
          <Li>
            <TimeResultFlex>
                <span style={{width: '20%'}}>
                  <p>04:00</p>
                  <p>PM</p>
                </span>
                <SetValueFlexDiv>
                    <span>Set: <p>{set16}</p></span>
                    <span>Val: <p>{value16}</p></span>
                </SetValueFlexDiv>
                {
                  <ResultNum>{result16}</ResultNum>
                }
            </TimeResultFlex>
            
          </Li>
          <Li>
            <TimeResultFlex>
                <span style={{width: '20%'}}>
                  <p>07:00</p>
                  <p>PM</p>
                </span>
                <SetValueFlexDiv>
                    <span>Set: <p>{set19}</p></span>
                    <span>Val: <p>{value19}</p></span>
                </SetValueFlexDiv>
                {
                  <ResultNum>{result19}</ResultNum>
                }
            </TimeResultFlex>
            
          </Li>
          <Li>
            <TimeResultFlex>
                <span style={{width: '20%'}}>
                  <p>10:00</p>
                  <p>PM</p>
                </span>
                <SetValueFlexDiv>
                    <span>Set: <p>{set22}</p></span>
                    <span>Val: <p>{value22}</p></span>
                </SetValueFlexDiv>
                {
                  <ResultNum>{result22}</ResultNum>
                }
            </TimeResultFlex>
          </Li>
       </Ul>
    </ContainerDiv>
  )
}

export default DailyResult
