import React from 'react';
import styled from 'styled-components';
import { FaRegCalendarAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';
import logo from '../images/logo/logo.png';
import giftIcon from '../images/icons/gift.png';
import calendar from '../images/icons/calendar.png';

const HeaderDiv = styled.div`
   padding: 10px 20px;
   display:flex;
   justify-content: space-between;
   align-items: center;
   background-color: ${props => props.theme.primary};
   border-bottom: 1px solid #191960;

   img {
     width: 60px;
   }
`

const CalendarBtn = styled.button`
     border: none;
     outline: none;
     background-color: transparent;
     cursor: pointer !important;
`

const Header = () => {

  return (
    <HeaderDiv>
        <img src = {logo} alt="Logo" />
        <div>
          <CalendarBtn style={{marginRight: "15px"}}>
            <Link to="message">
                {/* <FaEnvelope size="25px" color="#4a4a4a" /> */}
                <img src={giftIcon} alt="mes" style={{width: "30px"}}/>
            </Link>
          </CalendarBtn>
          <CalendarBtn>
            <Link to="result">
                {/* <FaRegCalendarAlt size="27px" color="#00A701" /> */}
                <img src={calendar} alt="mes" style={{width: "30px"}}/>
            </Link>
          </CalendarBtn>
        </div>
    </HeaderDiv>
  )
}

export default Header;