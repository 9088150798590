import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import {liveHandler} from '../store/liveNumSlice';
import {io} from "socket.io-client";
import { getMethod } from '../Apis/apis';
import Marquee from "react-fast-marquee";
import {FiCheck} from 'react-icons/fi';

const ContainerDiv = styled.div`
   padding: 15px 20px;
   background-color: ${props => props.theme.primary}
`
const TextBlink = keyframes`
    50% {
        opacity: 1;
    }
    57% {
        opacity: 0;
    }
    64% {
        opacity: 1;
    }
    71% {
        opacity: 0;
    }
    78% {
        opacity: 1;
    }
`

const LiveNumber = styled.h1`
   text-align: center;
   user-select: none;
   color: #FF014A;
   font-size: 65px;
   font-weight: bolder;
   text-shadow: 0 4.36px 8.896px #353535, 0 -2px 1px #fff;
   margin-bottom: 10px;
`
const LiveNumberAnimation = styled.h1`
   text-align: center;
   user-select: none;
   color: #FF014A;
   font-size: 65px;
   font-weight: bolder;
   text-shadow: 0 4.36px 8.896px #353535, 0 -2px 1px #fff;
   margin-bottom: 10px;
   animation: ${TextBlink} 4s linear infinite;
`

const UpdateTime = styled.div`
   margin: 0;
   font-size: 14px;
   color: ${props => props.theme.subSecondary};
   font-weight: 600;
   text-align: center;
   margin-bottom: 5px;
   user-select: none;
`

const SetValueContainer = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   border-bottom: 1px solid #ffffff;
   margin-bottom: 10px;
`
const LiveCard = styled.div`
    background-color: transparent;
    color: ${props => props.theme.subSecondary};
    display: flex;
    flex-direction: column;
    align-items:center;
    margin: 0 30px 10px 30px;
    user-select: none;
    width: 100px;
    span {
        background-color: transparent;
        font-size: 14px;
        font-weight: bolder;
        letter-spacing: 0.5px;
    }
    p {
        font-size: 18px;
        font-weight: 500;
        font-weight: bolder;
        letter-spacing: 0.5px;
        margin-bottom: 0;
        margin-top: 2px;
    }
`

const LiveSetValue = styled.p`
    animation: ${TextBlink} 4s linear infinite;
`
let currentDate = moment().tz("Asia/Yangon").format("DD-MMMM-YYYY");
const Live = () => {
    const [hourTime,setHourTime] = useState("--");
    const [minuteTime,setMinuteTime] = useState("--");
    const [secondTime,setSecondTime] = useState("--");
    const [animate10,setAnimate10] = useState(false);
    const [animate13,setAnimate13] = useState(false);
    const [animate16,setAnimate16] = useState(false);
    const [animate19,setAnimate19] = useState(false);
    const [animate22,setAnimate22] = useState(false);
    const [check10,setCheck10] = useState(true);
    const [check13,setCheck13] = useState(true);
    const [check16,setCheck16] = useState(true);
    const [check19,setCheck19] = useState(true);
    const [check22,setCheck22] = useState(true);
    const [message,setMessage] = useState("Hello");
    const [set,setSet] = useState("!!");
    const [value,setValue] = useState("!!");
    const [result,setResult] = useState("!!");
    const mounted = useRef(false);
    const dispatch = useDispatch();
    let date = moment().tz("Asia/Yangon").format("YYYY-MM-DD");

    useEffect(()=>{
        const socket = io(`${process.env.REACT_APP_HOST}/live`);
            socket.connect();
            socket.on("live", res => {
                let timeconvert = moment().tz("Asia/Yangon").format("HH:mm:ss");
                if(timeconvert > "08:00:00" && timeconvert < "09:59:58"){
                    if(res){
                        setSet(res.set);
                        setValue(res.value);
                        setResult(res.result);
                        dispatch(liveHandler(res.result));
                    }
                }
                
                if(timeconvert > "10:06:00" && timeconvert < "12:59:58"){
                    if(res){
                        setSet(res.set);
                        setValue(res.value);
                        setResult(res.result);
                        dispatch(liveHandler(res.result));
                    }
                }

                if(timeconvert > "13:06:00" && timeconvert < "15:59:58"){
                    if(res){
                        setSet(res.set);
                        setValue(res.value);
                        setResult(res.result);
                        dispatch(liveHandler(res.result));
                    }
                }

                if(timeconvert > "16:06:00" && timeconvert < "18:59:58"){
                    if(res){
                        setSet(res.set);
                        setValue(res.value);
                        setResult(res.result);
                        dispatch(liveHandler(res.result));
                    }
                }

                if(timeconvert > "19:06:00" && timeconvert < "21:59:58"){
                    if(res){
                        setSet(res.set);
                        setValue(res.value);
                        setResult(res.result);
                        dispatch(liveHandler(res.result));
                    }
                }
            });
            return () => {
            socket.disconnect();
        };
    },[]);

    useEffect(()=>{
        mounted.current = true;
        const time = setInterval(updateTime , 1000);
        return () => {
            mounted.current = false;
            clearInterval(time);
        };
    },[]);

    const updateTime = () => {
        let currentTime = moment().tz("Asia/Yangon");
        let hour = (currentTime.hours()<10?'0':'') + currentTime.hours();
        let minute = (currentTime.minutes()<10?'0':'') + currentTime.minutes();
        let second = (currentTime.second()<10?'0':'') + currentTime.second();
        setHourTime(hour);
        setMinuteTime(minute);
        setSecondTime(second);

        let ten = "08:00:00";
        let endTen = "09:59:58";
        
        let one = "10:06:00";
        let endOne = "12:59:58";

        let four = "13:06:00";
        let endFour = "15:59:58";
  
        let seven = "16:06:00";
        let endSeven = "18:59:58";

        let nightTen = "19:06:00";
        let endNightTen = "21:59:58";


        let timeconvert = moment().tz("Asia/Yangon").format("HH:mm:ss");
    
        if(timeconvert > "09:59:59" && timeconvert < "10:05:59"){
            (async()=>{
                let res = await getMethod(`/number/filter?date=${date}`);
                if(res){
                    if(res.data.length > 0){
                        res.data.map(el => {
                            if(el.time === "10:00 AM"){
                                setSet(el.set);
                                setValue(el.value);
                                setResult(el.result);
                            }
                        })
                    }
                }
                
            })();
        };

        if(timeconvert > "12:59:59" && timeconvert < "13:05:59"){
            (async()=>{
                let res = await getMethod(`/number/filter?date=${date}`);
                if(res){
                    if(res.data.length > 0){
                        res.data.map(el => {
                            if(el.time === "01:00 PM"){
                                setSet(el.set);
                                setValue(el.value);
                                setResult(el.result);
                            }
                        })
                    }
                }
                
            })();
        };

        if(timeconvert > "15:59:59" && timeconvert < "16:05:59"){
            (async()=>{
                let res = await getMethod(`/number/filter?date=${date}`);
                if(res){
                    if(res.data.length > 0){
                        res.data.map(el => {
                            if(el.time === "04:00 PM"){
                                setSet(el.set);
                                setValue(el.value);
                                setResult(el.result);
                            }
                        })
                    }
                }
                
            })();
        };

        if(timeconvert > "18:59:59" && timeconvert < "19:05:59"){
            (async()=>{
                let res = await getMethod(`/number/filter?date=${date}`);
                if(res){
                    if(res.data.length > 0){
                        res.data.map(el => {
                            if(el.time === "07:00 PM"){
                                setSet(el.set);
                                setValue(el.value);
                                setResult(el.result);
                            }
                        })
                    }
                }
                
            })();
        };

        if(timeconvert > "21:59:59" && timeconvert < "23:59:59"){
            (async()=>{
                let res = await getMethod(`/number/filter?date=${date}`);
                if(res){
                    if(res.data.length > 0){
                        res.data.map(el => {
                            if(el.time === "10:00 PM"){
                                setSet(el.set);
                                setValue(el.value);
                                setResult(el.result);
                            }
                        })
                    }
                }
                
            })();
        };

        if (timeconvert > ten && timeconvert < endTen){
            (async () =>{
                let res = await getMethod('/close');
                if(res){
                   if(res.data.length > 0){
                        if(res.data[0].status){
                            setAnimate10(true);
                            setCheck10(true);
                        }else{
                            setAnimate10(false);
                            setCheck10(false);
                        }
                   }
                }
            })();
        }else{
            setAnimate10(false);
            setCheck10(false);
        };

        if (timeconvert > one && timeconvert < endOne){
            (async () =>{
                let res = await getMethod('/close');
                if(res){
                   if(res.data.length > 0){
                        if(res.data[0].status){
                            setAnimate13(true);
                            setCheck13(true);
                        }else{
                            setAnimate13(false);
                            setCheck13(false);
                        };
                   };
                };
            })();
        }else{
            setAnimate13(false);
            setCheck13(false);
        };

        if (timeconvert > four && timeconvert < endFour){
            (async () =>{
                let res = await getMethod('/close');
                if(res){
                   if(res.data.length > 0){
                        if(res.data[0].status){
                            setAnimate16(true);
                            setCheck16(true);
                        }else{
                            setAnimate16(false);
                            setCheck16(false)
                        }
                   }
                }
            })();
        }else{
            setAnimate16(false);
            setCheck16(false)
        };

        if (timeconvert > seven && timeconvert < endSeven){
            (async () =>{
                let res = await getMethod('/close');
                if(res){
                   if(res.data.length > 0){
                        if(res.data[0].status){
                            setAnimate19(true);
                            setCheck19(true);
                        }else{
                            setAnimate19(false);
                            setCheck19(false)
                        }
                   }
                }
            })();
        }else{
            setAnimate19(false);
            setCheck19(false)
        };

        if (timeconvert > nightTen && timeconvert < endNightTen){
            (async () =>{
                let res = await getMethod('/close');
                if(res){
                   if(res.data.length > 0){
                        if(res.data[0].status){
                            setAnimate22(true);
                            setCheck22(true);
                        }else{
                            setAnimate22(false);
                            setCheck22(false)
                        }
                   }
                }
            })();
        }else{
            setAnimate22(false);
            setCheck22(false)
        };
    };

    useEffect(()=>{
        (async()=>{
           const res = await getMethod('/alert');
           if(res){
             if(res.data){
               setMessage(res.data);
             }
           };
            })();
    },[]);

  return (
    <ContainerDiv>
        {
            animate10 || animate13 || animate16 || animate19 || animate22 ? <LiveNumberAnimation>{result}</LiveNumberAnimation> : <LiveNumber>{result}</LiveNumber>
        }
         <SetValueContainer>
             <LiveCard>
                 <span>Set</span>
                 {animate10 || animate13 || animate16 || animate19 || animate22? <LiveSetValue>{set}</LiveSetValue> :<p>{set}</p>}
             </LiveCard>
             <LiveCard>
                 <span>Value</span>
                 {animate10 || animate13 || animate16 || animate19 || animate22? <LiveSetValue>{value}</LiveSetValue> :<p>{value}</p>}
             </LiveCard>
         </SetValueContainer>
         <UpdateTime>
            <div style={{marginBottom: '5px'}}> {(check10 || check13 || check16 || check19 || check22 ) ? null : <FiCheck color='#FF014A' size="20px" style={{marginRight: '5px'}} />} Live Updated</div>
            <div>{currentDate} {hourTime}:{minuteTime}:{secondTime}</div>
        </UpdateTime>
        <Marquee 
            speed={40}
            gradient={false}
            pauseOnHover={true}
            style={{
                backgroundColor: "rgb(0 0 0 / 23%)",
                userSelect: 'none',
                marginTop: '10px',
                padding: '7px',
                fontSize: '14px',
                borderRadius: '5px',
                color: "#FFF"
            }}
         >
            {message.length === 1 && message[0].name}
        </Marquee>
    </ContainerDiv>
  )
}

export default Live
